import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import styled from "styled-components"

const Container = styled.div`
  margin-top: 60px;

  @media all and (min-width: 992px) {
    margin-top: 0;
  }
`

const SideContainer = styled.div`
  min-width: 250px;
  position: fixed;
  height: 100%;
  z-index: -3;
  top: 0;
  left: 0;
  overflow-x: hidden;
  background: black;
  padding: 0 15px;
  ::-webkit-scrollbar {
    display: none;
  }
  @media all and (min-width: 992px) {
    z-index: 3;
  }
`

const ContentContainer = styled.div`
  height: 92vh;
  margin-left: 0;
  @media all and (min-width: 992px) {
    padding: 0 80px;
    margin-left: 250px !important;
  }
`

const StyledH1 = styled.h1`
  color: #ff69b4;
  @font-face {
    font-family: "sambal-light";
    src: url("Sambal-Light.otf");
    font-display: block;
  }
  font-family: sambal-light !important;
  font-size: 70px;
`

const title = "News"

const News = props => {
  return (
    <Layout activeMenu={title}>
      <SEO title={title} />
      <Container>
        {/* Data for the all the news, for left sidebar navigation */}
        <SideContainer />
        {/* Data for the individual news */}
        <ContentContainer>
          <StyledH1>
            CHECK <br /> BACK SOON
          </StyledH1>
        </ContentContainer>
      </Container>
    </Layout>
  )
}

export default News
